import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    CircularProgress,
} from '@mui/material';
import {
    default as React,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import { server } from '../../utils/axios';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';

const Index = () => {
    const [settings, setSettings] = useState([]);
    const { modalState, closeModal, openModal } = useModal();
    const [loading, setLoading] = useState(null); // null means no loading, index value will represent which row is loading
    const [delloading, setDelLoading] = useState(null);

    const errorHandler = useErrorHandler();

    const handlers = useForm(
        useMemo(
            () => ({
                key: { required: true },
                value: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    const getSetting = useCallback(async () => {
        try {
            const response = await server.get(`/settings/`);
            const settings = response.data;
            setSettings(settings);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler]);

    const handleEditSetting = async (index, key, value) => {
        setLoading(index); // Set loading state to the current row index
        try {
            await server.patch(`/settings/${key}/`, { value });
            getSetting();
        } catch (e) {
            errorHandler(e);
        } finally {
            setLoading(null); // Reset loading state once finished
        }
    };

    const handleDeleteSetting = async (index, key) => {
        setDelLoading(index); // Set loading state to the current row index
        try {
            await server.delete(`/settings/${key}/`);
            getSetting();
        } catch (e) {
            errorHandler(e);
        } finally {
            setDelLoading(null); // Reset loading state once finished
        }
    };

    const onSubmit = async () => {
        closeModal();
        getSetting();
    };

    useEffect(() => {
        getSetting();
    }, [getSetting]);

    return (
        <Container maxWidth="false">
            <Box pt={3}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box>
                        <Typography variant="h4" fontWeight={500}>
                            Settings
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ wordSpacing: '2px' }}>
                            Manage your settings from this page
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openModal}>
                            Add setting
                        </Button>
                    </Box>
                </Box>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        mb={4}
                        overflow="auto">
                        {settings.map((setting, index) => (
                            <>
                                <Typography
                                    textTransform="capitalize"
                                    variant="body1"
                                    fontWeight={600}>
                                    {setting.key}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Input
                                        name={`value-${index}`} // Unique name for each input
                                        variant="outlined"
                                        size="small"
                                        defaultValue={setting.value}
                                        onChange={e =>
                                            handlers.setValues({
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '90%',
                                                lg: '400px',
                                            },
                                        }}
                                    />
                                    <IconButton
                                        sx={{ ml: 2 }}
                                        onClick={() =>
                                            handleEditSetting(
                                                index,
                                                setting.key,
                                                handlers.values[
                                                    `value-${index}`
                                                ]
                                            )
                                        }
                                        disabled={loading === index}>
                                        {loading === index ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <EditOutlinedIcon />
                                        )}
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            handleDeleteSetting(
                                                index,
                                                setting.key
                                            )
                                        }
                                        disabled={delloading === index}>
                                        {delloading === index ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <DeleteOutlineOutlinedIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Typography
                                    textTransform="capitalize"
                                    variant="caption"
                                    fontWeight={600}
                                    mb={4}>
                                    {setting.description}
                                </Typography>
                            </>
                        ))}
                    </Box>
                </Form>
            </Box>

            {/* Modal for Adding Setting */}
            <Dialog
                open={modalState}
                onClose={closeModal}
                maxWidth="sm"
                fullWidth>
                <DialogTitle>Add New Setting</DialogTitle>
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={'/settings/'}
                    method="POST"
                    axiosInstance={server}
                    style={{ width: '100%' }}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Input
                                    placeholder="Key"
                                    name="key"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder="Value"
                                    name="value"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Submit>
                            {loader => (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={Boolean(loader)}
                                    sx={{
                                        textTransform: 'capitalize',
                                    }}
                                    endIcon={loader}>
                                    Add
                                </Button>
                            )}
                        </Submit>
                    </DialogActions>
                </Form>
            </Dialog>
        </Container>
    );
};

export default Index;
