import Add from '@mui/icons-material/Add';
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Select,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EventEmitter } from 'events';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import { useSettings } from '../../hooks/Authorize';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';
import { server } from '../../utils/axios';
import { CreateEditOrder } from './CreateEditOrder';
import CreateItem from './CreateItems';
import OrderItem from './OrderItem';
import ActionIcon from '../../components/ActionIcon';
import CachedIcon from '@mui/icons-material/Cached';

const itemEventListener = new EventEmitter();

const NewOrder = () => {
    const { orderId } = useParams();
    const isEditMode = Boolean(orderId);
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const { openModal, closeModal, modalState } = useModal();
    const orderEditModal = useModal();
    const [products, setProducts] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);
    const { 'order-statuses': orderStatus } = useSettings();
    const orderStatuses = orderStatus.split(',') || [];
    const queryClient = useQueryClient();

    const {
        data = {},
        isPending,
        isFetching,
    } = useQuery({
        queryKey: ['order', orderId],
        queryFn: async () => {
            if (!orderId) return {};

            const response = await server.get(`/orders/${orderId}`, {
                params: { expand: 'customer' },
            });

            return response.data;
        },
        onError: e => errorHandler(e),
    });

    const updateStatusMutation = useMutation({
        mutationFn: async newStatus => {
            return await server.patch(`/orders/${orderId}`, {
                status: newStatus,
            });
        },
        onMutate: data => {
            queryClient.cancelQueries(['order', orderId]);

            const prevOrder = queryClient.getQueryData(['order', orderId]);

            queryClient.setQueryData(['order', orderId], old => ({
                ...old,
                status: data,
            }));

            return { prevOrder };
        },
        onSuccess: () => {
            showSuccess('Order status updated successfully');
        },
        onError: (err, updatedOrder, context) => {
            queryClient.setQueryData(['order', orderId], context.prevOrder);
            showError('Failed to update status');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['order', orderId]);
        },
    });

    const getProducts = useCallback(
        async search => {
            setProducts([]);

            try {
                const params = { search };
                const response = await server.get(`/products/`, { params });
                const products = response.data;

                setProducts(products.results);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    const getOrderItems = useCallback(async () => {
        if (!isEditMode) return;
        setOrderItems([]);

        try {
            const response = await server.get(`/order-items/`, {
                params: { order: orderId, expand: 'product' },
            });
            const orderItems = response.data;

            setOrderItems(orderItems.results);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, isEditMode, orderId]);

    const openOrderItemEditModal = function (orderItem) {
        setSelectedOrderItem(orderItem);
        openModal();
    };

    const openOrderEditModal = function () {
        orderEditModal.openModal();
    };

    const closeOrderEditModal = function () {
        orderEditModal.closeModal();
    };

    const refresh = () => queryClient.invalidateQueries(['order', orderId]);

    useEffect(() => {
        const handleItemAdd = data => setOrderItems([...orderItems, data]);
        const handleItemEdit = orderItem => {
            const index = orderItems.findIndex(
                item => item.id === orderItem.id
            );
            orderItems[index] = orderItem;

            setOrderItems([...orderItems]);
        };
        itemEventListener.on('add', handleItemAdd);
        itemEventListener.on('edit', handleItemEdit);

        return () => {
            itemEventListener.removeListener('add', handleItemAdd);
            itemEventListener.removeListener('edit', handleItemEdit);
        };
    }, [orderItems]);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    useEffect(() => {
        getOrderItems();
    }, [getOrderItems]);

    return (
        <Box
            bgcolor="background.paper"
            height="calc(100vh - 76px)"
            overflow="auto"
            display="flex"
            flexDirection="column"
            p={0}>
            <Typography variant="h5" fontWeight={600} color="primary" p={2}>
                {isEditMode ? (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            Edit Order{' '}
                            {isPending ? (
                                <CircularProgress size={20} sx={{ ml: 1 }} />
                            ) : data.shopify_id ? (
                                `#${data.shopify_id} `
                            ) : (
                                '(Manual Order)'
                            )}
                        </Grid>
                        {!isPending && (
                            <>
                                <Grid item>
                                    <ActionIcon
                                        title="Edit"
                                        onClick={openOrderEditModal}
                                        tooltipProps={{ placement: 'left' }}>
                                        <EditIcon
                                            fontSize="small"
                                            sx={{ color: 'primary.main' }}
                                        />
                                    </ActionIcon>
                                </Grid>
                                <Grid item>
                                    <ActionIcon
                                        title="Refresh"
                                        onClick={refresh}
                                        tooltipProps={{ placement: 'left' }}>
                                        {isFetching ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <CachedIcon
                                                fontSize="small"
                                                sx={{ color: 'primary.main' }}
                                            />
                                        )}
                                    </ActionIcon>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    `New Order`
                )}
            </Typography>
            <Divider variant="fullWidth" />
            {isPending ? (
                <Loading message="Please wait, while your data is loading..." />
            ) : (
                <Box p={2}>
                    <Grid container spacing={2} sx={{ mb: 3, mt: 2 }}>
                        {/* Customer Information */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Customer Information
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}>
                                                <Typography variant="body1">
                                                    <strong>Name</strong>
                                                </Typography>
                                                <Typography>
                                                    {data.customer_name}
                                                </Typography>
                                            </Grid>
                                            {data.customer_email && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}>
                                                    <Typography variant="body1">
                                                        <strong>Email</strong>
                                                    </Typography>
                                                    <Typography>
                                                        {data.customer_email}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Shipping Address */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Shipping Address
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            {data.shipping_address ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Address
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                data
                                                                    .shipping_address
                                                                    .address1
                                                            }
                                                            ,{' '}
                                                            {
                                                                data
                                                                    .shipping_address
                                                                    .city
                                                            }
                                                            ,{' '}
                                                            {
                                                                data
                                                                    .shipping_address
                                                                    .country
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    {data.shipping_address
                                                        .address2 && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Address2
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .address2
                                                                }
                                                                ,{' '}
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .city
                                                                }
                                                                ,{' '}
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .country
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {data.shipping_address
                                                        .phone && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Phone
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .phone
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {data.shipping_address
                                                        .company && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Company
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .company
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {data.shipping_address
                                                        .province && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Province
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {
                                                                    data
                                                                        .shipping_address
                                                                        .province
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Postal Code
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                data
                                                                    .shipping_address
                                                                    .zip
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Box m={5} ml={15}>
                                                    <Typography>
                                                        No shipping address
                                                        details available.
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Order Information */}
                        <Grid item lg={4} xs={12}>
                            <Card
                                sx={{
                                    minHeight: 242,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}>
                                <CardContent>
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            fontWeight={500}>
                                            Order Information
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            {data ? (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Order ID
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {data.shopify_id}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Order Date
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {new Date(
                                                                data.created_on
                                                            ).toLocaleDateString()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Financial Status
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                data.financial_status
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Total Price
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            €{data.total_price}
                                                        </Typography>
                                                    </Grid>
                                                    {data.note && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Note
                                                                </strong>
                                                            </Typography>
                                                            <Typography>
                                                                {data.note}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Typography variant="body1">
                                                            <strong>
                                                                Status
                                                            </strong>
                                                        </Typography>
                                                        <Select
                                                            value={data.status}
                                                            disabled={
                                                                updateStatusMutation.isPending
                                                            }
                                                            onChange={e =>
                                                                updateStatusMutation.mutate(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }>
                                                            {orderStatuses.map(
                                                                status => (
                                                                    <MenuItem
                                                                        value={
                                                                            status
                                                                        }>
                                                                        {status}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Box m={5} ml={15}>
                                                    <Typography>
                                                        No order details
                                                        available.
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Box
                        display="flex"
                        flexDirection="column"
                        mb={4}
                        overflow="auto">
                        {isEditMode && (
                            <>
                                {orderItems.map(orderItem => (
                                    <OrderItem
                                        key={orderItem.id}
                                        orderItem={orderItem}
                                        openEditModal={openOrderItemEditModal}
                                        itemEventListener={itemEventListener}
                                    />
                                ))}
                                <Box textAlign="center" my={2}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<Add />}
                                        onClick={openModal}>
                                        Add Order Items
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                    <CreateEditOrder
                        open={orderEditModal.modalState}
                        order={data}
                        handleClose={closeOrderEditModal}
                    />
                </Box>
            )}
            {isEditMode && (
                <Modal
                    open={modalState}
                    onClose={closeModal}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <CreateItem
                        orderItem={selectedOrderItem}
                        closeModal={closeModal}
                        products={products}
                        getProducts={getProducts}
                        orderId={orderId}
                        itemEventListener={itemEventListener}
                    />
                </Modal>
            )}
        </Box>
    );
};

export default NewOrder;
