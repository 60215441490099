import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Divider,
    Modal,
    Card,
    IconButton,
    Grid,
    Tabs,
    Tab,
} from '@mui/material';
import DataGrid from '../../components/DataGrid';
import useErrorHandler from '../../hooks/useErrorHandler';
import useLoader from '../../hooks/useLoader';
import { server } from '../../utils/axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactJson from 'react-json-view';
import { CustomTabPanel } from '../../components/TabPanel';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        width: 100,
    },
    {
        field: 'method',
        headerName: 'Method',
        width: 100,
        sortable: false,
        renderCell: params => (
            <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{ color: 'primary.main' }}>
                {params.row.method}
            </Typography>
        ),
    },
    {
        field: 'order',
        headerName: 'Order',
        sortable: false,
        width: 200,
    },
    {
        field: 'firedAt',
        headerName: 'Fired At',
        sortable: false,
        flex: 1,
        valueGetter: params => new Date(params.row.fired_at).toString(),
    },
    {
        field: 'handled',
        headerName: 'Handled',
        sortable: false,
        width: 200,
        align: 'center',
        headerAlign: 'center',
        renderCell: params =>
            params.row.handled ? <CheckCircleIcon /> : <CancelIcon />,
    },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);
    const { startLoading, endLoading, loaderState } = useLoader();
    const errorHandler = useErrorHandler();
    const [selectedRow, setSelectedRow] = useState(null);
    const [tab, setTab] = useState('payload');

    const getWebhooks = useCallback(async () => {
        startLoading();
        setRows([]);

        const params = {
            limit: paginationModel.pageSize,
            offset: (paginationModel.page + 1 - 1) * paginationModel.pageSize,
        };

        if (search) params.search = search;

        try {
            const response = await server.get(`/webhooks/list/shopify/`, {
                params,
            });
            const webhooks = response.data;

            setRows(webhooks.results);
            setRowCount(webhooks.count);
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [paginationModel, search, startLoading, endLoading, errorHandler]);

    useEffect(() => {
        getWebhooks();
    }, [getWebhooks]);

    return (
        <>
            <Container maxWidth="false">
                <Box pt={3}>
                    <Typography variant="h4" fontWeight={500}>
                        Webhooks
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ wordSpacing: '2px' }}>
                        Manage your webhooks from this page
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
                </Box>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode="server"
                    pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
                    autoHeight
                    onRowClick={data => setSelectedRow(data.row)}
                    disableSelectionOnClick
                    loading={loaderState}
                    rowCount={rowCount}
                    slotProps={{
                        toolbar: { setSearch, search },
                    }}
                    checkboxSelection={false}
                />
            </Container>

            <Modal
                open={Boolean(selectedRow)}
                onClose={() => setSelectedRow(null)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Card
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                        borderRadius: '18px',
                        overflowY: 'auto',
                        // minHeight: '30vh',
                        height: '90vh',
                        maxWidth: '1000px',
                        width: '100%',
                        mx: 2,
                        '::-webkit-scrollbar': { display: 'none' },
                        p: 2,
                    }}>
                    <Grid container>
                        <Grid item xs>
                            <Tabs
                                value={tab}
                                onChange={(e, newValue) => setTab(newValue)}>
                                <Tab label="Payload" value="payload" />
                                <Tab label="Error" value="error" />
                            </Tabs>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setSelectedRow(null)}>
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mb: 3 }} />
                    <CustomTabPanel value="payload" index={tab}>
                        <ReactJson src={selectedRow?.data} />
                    </CustomTabPanel>
                    <CustomTabPanel value="error" index={tab}>
                        <pre>{selectedRow?.error}</pre>
                    </CustomTabPanel>
                </Card>
            </Modal>
        </>
    );
};

export default Index;
