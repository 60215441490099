import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessage } from '../../components/Header';
import Loading from '../../components/Loading';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import { server } from '../../utils/axios';
import DeleteModal from './DeleteModal';

const EditCreateTriggers = () => {
    const { trigersId } = useParams();
    const isEditMode = Boolean(trigersId);
    const { showSuccess } = useMessage();
    const { startLoading, endLoading, loaderState } = useLoader();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const deleteModal = useModal();

    const [events, setEvents] = useState([]);
    const [actions, setActions] = useState([]);
    const [dynamicFields, setDynamicFields] = useState({});

    const handlers = useForm(
        useMemo(
            () => ({
                description: {},
                event: { required: true },
                action: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    const selectedAction = useMemo(
        () => actions.find(action => action.name === handlers.values.action),
        [actions, handlers.values.action]
    );

    console.log(handlers);

    const setTriggerValues = handlers.setValues;

    const getTriggers = useCallback(async () => {
        if (!isEditMode) return;
        if (!actions.length) return;

        startLoading();
        try {
            const response = await server.get(`/triggers/${trigersId}/`);
            const { event, action, description, more_attributes } =
                response.data;

            setTriggerValues({
                event,
                action,
                description,
            });

            const selectedAction = actions.find(act => act.name === action);

            // If the action has specific fields, merge them with the dynamic fields
            setDynamicFields(
                selectedAction.fields.reduce((acc, field) => {
                    acc[field.name] = more_attributes[field.name] || '';
                    return acc;
                }, {})
            );
        } catch (e) {
            errorHandler(e);
        } finally {
            endLoading();
        }
    }, [
        trigersId,
        setTriggerValues,
        startLoading,
        endLoading,
        errorHandler,
        actions,
        isEditMode,
    ]);

    const fetchEvents = useCallback(async () => {
        try {
            const response = await server.get('/triggers/events/');
            setEvents(response.data);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler]);

    const fetchActions = useCallback(async () => {
        try {
            const response = await server.get('/triggers/actions/');
            setActions(response.data);
        } catch (error) {
            errorHandler(error);
        }
    }, [errorHandler]);

    const handleActionChange = event => {
        setDynamicFields({});

        handleInputChange(event);
    };

    const handleInputChange = event => {
        const { name, value } = event.target;

        setTriggerValues({
            [name]: value, // update event field
        });
    };

    const onSubmit = async response => {
        console.log('submitting form', handlers.values);

        try {
            if (response.data) {
                showSuccess('Trigger saved successfully');
                navigate('/triggers');
            }
        } catch (error) {
            errorHandler(error);
        }
    };

    const handleDynamicFieldsChange = e =>
        setDynamicFields(prevFields => ({
            ...prevFields,
            [e.target.name]: e.target.value,
        }));

    useEffect(() => {
        if (isEditMode) {
            getTriggers();
        }
    }, [isEditMode, getTriggers]);

    useEffect(() => {
        fetchEvents();
        fetchActions();
    }, [fetchEvents, fetchActions]);

    return (
        <Box
            bgcolor="background.paper"
            height="calc(100vh - 76px)"
            overflow="auto"
            display="flex"
            flexDirection="column"
            p={0}>
            <Grid container alignItems="center" sx={{ pr: 2 }}>
                <Grid item xs>
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        color="primary"
                        p={2}>
                        {isEditMode ? `Edit Triggers` : `New Triggers`}
                    </Typography>
                </Grid>
                {isEditMode && (
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={deleteModal.openModal}
                            endIcon={<DeleteIcon />}>
                            Delete
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider variant="fullWidth" />
            {loaderState ? (
                <Loading message="Please wait, while your data is loading..." />
            ) : (
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    action={
                        isEditMode ? `/triggers/${trigersId}/` : '/triggers/'
                    }
                    method={isEditMode ? 'patch' : 'post'}
                    axiosInstance={server}
                    retainOnSubmit
                    final={values => ({
                        ...values,
                        more_attributes: dynamicFields,
                    })}
                    onError={errorHandler}
                    style={{ flexGrow: 1, position: 'relative' }}>
                    <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        mb={4}
                        overflow="auto">
                        {/* Description Field */}
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems="center"
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant="body2" fontWeight={600}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} display="flex">
                                <Input
                                    name="description"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '90%',
                                            lg: '316px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Event Selection */}
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems="center"
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant="body2" fontWeight={600}>
                                    Event
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} display="flex">
                                <FormControl fullWidth>
                                    <Select
                                        name="event"
                                        value={handlers.values.event || ''}
                                        onChange={handleInputChange}
                                        required
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '90%',
                                                lg: '316px',
                                            },
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 300,
                                                    overflow: 'auto',
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                        }}>
                                        {events.map(event => (
                                            <MenuItem
                                                key={event.name}
                                                value={event.name}>
                                                {`${event.name} (${event.description})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Action Selection */}
                        <Grid
                            container
                            columnSpacing={5}
                            rowSpacing={1}
                            alignItems="center"
                            mb={2}>
                            <Grid item xs={12} lg>
                                <Typography variant="body2" fontWeight={600}>
                                    Action
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} display="flex">
                                <FormControl fullWidth>
                                    <Select
                                        name="action"
                                        value={handlers.values.action || ''}
                                        onChange={handleActionChange}
                                        required
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '90%',
                                                lg: '316px',
                                            },
                                        }}>
                                        {actions.map(action => (
                                            <MenuItem
                                                key={action.name}
                                                value={action.name}>
                                                {`${action.name} (${action.description})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Dynamic Fields */}

                        <Box>
                            {selectedAction?.fields.map(field => (
                                <Grid
                                    container
                                    mb={2}
                                    columnSpacing={5}
                                    rowSpacing={1}
                                    alignItems="center"
                                    key={field.name}>
                                    <Grid item xs={12} lg>
                                        <Typography
                                            textTransform="capitalize"
                                            variant="body2"
                                            fontWeight={600}>
                                            {field.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={10} display="flex">
                                        <TextField
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    lg: '316px',
                                                },
                                            }}
                                            fullWidth
                                            placeholder={field.description}
                                            name={field.name}
                                            value={
                                                dynamicFields[field.name] || ''
                                            } // Ensure value is set
                                            onChange={handleDynamicFieldsChange}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>

                        {/* Submit Button */}
                        <Stack
                            direction="row"
                            p={2}
                            spacing={2}
                            sx={{
                                boxShadow: '0 -4px 5px -3px rgba(0,0,0,.1);',
                                backgroundColor: 'background.paper',
                                zIndex: 1100,
                                position: 'fixed',
                                bottom: 0,
                                width: {
                                    xs: '100%',
                                    xm: `calc(100% - 280px)`,
                                },
                            }}>
                            <Submit>
                                {loader => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={Boolean(loader)}
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}
                                        endIcon={loader}>
                                        Save
                                    </Button>
                                )}
                            </Submit>
                            <Button
                                onClick={() => navigate(-1)}
                                sx={{
                                    bgcolor: 'background.default',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                }}>
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Form>
            )}
            <DeleteModal modal={deleteModal} triggerId={trigersId} />
        </Box>
    );
};

export default EditCreateTriggers;
